<template>
  <div class="min-h-screen bg-gradient-to-br from-green-50 to-white py-10">
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      
      <!-- Titre principal -->
      <h1 class="text-3xl font-extrabold text-green-700 mb-8 text-center border-b-4 border-green-500 pb-3">
        Liste des Clients
      </h1>

      <!-- Champ de recherche -->
      <div class="mb-4">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Rechercher un client par Nom ou Email Gologin..."
          class="w-full px-4 py-2 border border-green-300 rounded focus:outline-none focus:ring-2 focus:ring-green-500"
        />
      </div>

      <!-- Tableau des clients -->
      <table class="min-w-full bg-white shadow-md rounded mb-4">
        <thead class="bg-green-200 text-green-900">
          <tr>
            <th class="py-3 px-4 text-left">Nom</th>
            <th class="py-3 px-4 text-left">Email Gologin</th>
            <th class="py-3 px-4 text-left">Comptes Utilisés</th>
            <th class="py-3 px-4 text-left">Mot de Passe Admin</th>
            <th class="py-3 px-4 text-left">Expiration</th>
            <th class="py-3 px-4 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          <!-- Utilise filteredClients au lieu de clients pour appliquer le filtre -->
          <tr 
            v-for="client in filteredClients" 
            :key="client.Email_Gologin" 
            class="border-b hover:bg-gray-50 transition"
          >
            <td class="py-2 px-4">{{ client.Nom }}</td>
            <td class="py-2 px-4">{{ client.Email_Gologin }}</td>
            <td class="py-2 px-4">{{ client.Comptes_utilis_s }}</td>
            <td class="py-2 px-4">
              <span v-if="client.mot_de_passe_admin">
                {{ client.mot_de_passe_admin }}
              </span>
              <span v-else class="text-gray-400">N/A</span>
            </td>
            <td class="py-2 px-4">
              <span v-if="client.expiration_mot_de_passe">
                {{ formatDate(client.expiration_mot_de_passe) }}
              </span>
              <span v-else class="text-gray-400">N/A</span>
            </td>
            <td class="py-2 px-4">
              <button
                class="bg-green-500 text-white py-1 px-3 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                @click="generateAdminPassword(client)"
              >
                Générer MDP Admin
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from '../plugins/axios'; 

export default {
  name: 'ClientList',
  data() {
    return {
      clients: [],
      searchQuery: '' // Pour stocker la saisie utilisateur
    };
  },
  computed: {
    /**
     * Filtre localement la liste des clients en fonction de searchQuery.
     * Recherche dans Nom et Email_Gologin (insensible à la casse).
     */
    filteredClients() {
      // Transforme la recherche en minuscule pour comparer plus facilement
      const query = this.searchQuery.toLowerCase();
      
      return this.clients.filter(client => {
        // Prépare les champs à comparer en minuscule
        const name = (client.Nom || '').toLowerCase();
        const email = (client.Email_Gologin || '').toLowerCase();
        
        // Vérifie si la recherche (query) est incluse dans Nom ou Email
        return name.includes(query) || email.includes(query);
      });
    }
  },
  methods: {
    /**
     * Récupère la liste de tous les clients.
     */
    async fetchClients() {
      try {
        const response = await axios.get('/admin2/clients');
        this.clients = response.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des clients :', error);
      }
    },

    /**
     * Génère ou régénère le mot de passe admin d'un client,
     * met à jour le client localement et affiche l'info.
     */
    async generateAdminPassword(client) {
      try {
        const response = await axios.post('/admin2/update-admin-password', {
          email_gologin: client.Email_Gologin
        });
        if (response.data && response.data.success) {
          client.mot_de_passe_admin = response.data.mot_de_passe_admin;
          client.expiration_mot_de_passe = response.data.expiration;

        }
      } catch (error) {
        console.error('Erreur lors de la génération du MDP admin :', error);
        alert(`Erreur : ${error.message}`);
      }
    },

    /**
     * Formate la date d'expiration (datetime) en format local lisible
     */
    formatDate(datetime) {
      if (!datetime) return '';
      const date = new Date(datetime);
      return date.toLocaleString(); 
    }
  },
  mounted() {
    this.fetchClients();
  }
};
</script>
