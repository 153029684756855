<template>
  <div class="min-h-screen bg-gradient-to-br from-green-50 to-white py-10">
    <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-extrabold text-green-700 mb-8 text-center border-b-4 border-green-500 pb-3">
        Gestion des Produits et Prix Stripe
      </h1>

      <!-- Formulaire produit -->
      <section class="bg-white p-6 rounded-lg shadow-lg mb-10">
        <h2 class="text-2xl font-bold text-green-600 mb-6 flex items-center gap-2">
          <span class="block h-1 w-8 bg-green-600 rounded"></span>
          {{ isEditingProduct ? 'Modifier un produit' : 'Ajouter un produit' }}
        </h2>

        <form @submit.prevent="addOrUpdateProduct" class="space-y-6">
          <div>
            <label for="productNameUS" class="block text-sm font-medium text-gray-700">Nom US</label>
            <input
              id="productNameUS"
              v-model="newProduct.nameUS"
              type="text"
              placeholder="Nom du produit (US)"
              class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
              required
            />
          </div>

          <div>
            <label for="productNameEU" class="block text-sm font-medium text-gray-700">Nom EU</label>
            <input
              id="productNameEU"
              v-model="newProduct.nameEU"
              type="text"
              placeholder="Nom du produit (EU)"
              class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
              required
            />
          </div>

          <div>
            <label for="stripeProductId" class="block text-sm font-medium text-gray-700">ID Produit Stripe</label>
            <input
              id="stripeProductId"
              v-model="newProduct.stripe_product_id"
              type="text"
              placeholder="ID Stripe du produit"
              class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
              required
            />
          </div>

          <div>
            <label for="descriptionUS" class="block text-sm font-medium text-gray-700">Description US</label>
            <textarea
              id="descriptionUS"
              v-model="newProduct.descriptionUS"
              placeholder="Description du produit (US)"
              class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            ></textarea>
          </div>

          <div>
            <label for="descriptionEU" class="block text-sm font-medium text-gray-700">Description EU</label>
            <textarea
              id="descriptionEU"
              v-model="newProduct.descriptionEU"
              placeholder="Description du produit (EU)"
              class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            ></textarea>
          </div>

          <div v-if="isEditingProduct">
            <label for="image" class="block text-sm font-medium text-gray-700">Image du produit</label>
            <input
              id="image"
              type="file"
              @change="e => (newProduct.imageFile = e.target.files[0])"
              accept="image/jpeg, image/png"
              class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
            <p v-if="newProduct.imagePath" class="text-sm text-gray-500 mt-1">
              Image actuelle : {{ newProduct.imagePath }}
            </p>
          </div>

          <div class="flex gap-4 pt-4 border-t border-gray-200">
            <button
              type="submit"
              class="bg-green-500 text-white font-semibold py-2 px-4 rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-200"
            >
              {{ isEditingProduct ? 'Modifier le produit' : 'Ajouter le produit' }}
            </button>

            <button
              v-if="isEditingProduct"
              type="button"
              @click="resetProductForm"
              class="bg-gray-500 text-white font-semibold py-2 px-4 rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 transition duration-200"
            >
              Annuler
            </button>
          </div>
        </form>
      </section>

      <!-- Liste des produits -->
      <section>
        <h2 class="text-2xl font-bold text-green-600 mb-6 flex items-center gap-2">
          <span class="block h-1 w-8 bg-green-600 rounded"></span>
          Produits existants
        </h2>

        <div v-if="products.length" class="bg-white p-6 rounded-lg shadow-lg">
          <ul class="divide-y divide-gray-200">
            <li
              v-for="product in products"
              :key="product.product_id"
              class="mb-6 pb-4 border-b border-gray-200"
            >
              <!-- Informations principales du produit -->
              <div class="mb-2">
                <p class="font-semibold text-gray-800">Nom US : {{ product.nameUS }}</p>
                <p class="font-semibold text-gray-800">Nom EU : {{ product.nameEU }}</p>
                <p class="text-sm text-gray-600">ID Stripe : {{ product.stripe_product_id }}</p>
              </div>

              <!-- Boutons existants (gérer prix, modifier, supprimer) -->
              <div class="flex flex-wrap gap-3">
                <button
                  @click="selectProduct(product)"
                  class="bg-blue-500 text-white font-semibold py-1 px-3 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                >
                  Gérer les prix
                </button>

                <button
                  @click="loadProductForEdit(product)"
                  class="bg-yellow-500 text-white font-semibold py-1 px-3 rounded hover:bg-yellow-700"
                >
                  Modifier
                </button>

                <button
                  @click="deleteProduct(product.product_id)"
                  class="bg-red-500 text-white font-semibold py-1 px-3 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-200"
                >
                  Supprimer
                </button>
              </div>

              <!-- Section 'days' et 'availability' pour le premier prix, en bas -->
              <div
                v-if="product.prices && product.prices.length"
                class="mt-4 p-4 border-t border-gray-200"
              >
                <div class="mb-2">
                  <label class="block mb-1">Days</label>
                  <input
                    type="number"
                    placeholder="Days..."
                    class="border border-gray-300 rounded px-2 py-1 w-20"
                    v-model="updatedDays[product.stripe_product_id]"
                  />
                </div>

                <div class="mb-2">
                  <label class="block mb-1">Availability</label>
                  <select
                    class="border border-gray-300 rounded px-2 py-1"
                    v-model="updatedAvailability[product.stripe_product_id]"
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>

                <button
                  @click="updateDaysAvailability(product)"
                  class="bg-green-500 text-white font-semibold py-1 px-3 rounded hover:bg-green-700 mt-2"
                >
                  Appliquer
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div v-else class="text-gray-500">Aucun produit trouvé.</div>
      </section>

      <!-- Gestion des prix -->
      <div v-if="selectedProduct" class="mt-10">
        <h2 class="text-2xl font-bold text-green-600 mb-6 flex items-center gap-2">
          <span class="block h-1 w-8 bg-green-600 rounded"></span>
          Prix pour {{ selectedProduct.nameUS }} / {{ selectedProduct.nameEU }}
        </h2>

        <section class="bg-white p-6 rounded-lg shadow-lg mb-10">
          <h3 class="text-xl font-bold text-green-600 mb-4">
            {{ isEditing ? 'Modifier un prix' : 'Ajouter un prix' }}
          </h3>
          <form @submit.prevent="isEditing ? updatePrice(formPrice.stripe_price_id) : addPrice()" class="space-y-6">
            <div>
              <label for="priceId" class="block text-sm font-medium text-gray-700">ID du prix</label>
              <input
                id="priceId"
                v-model="formPrice.stripe_price_id"
                type="text"
                placeholder="ID Stripe du prix"
                class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                :readonly="isEditing"
                required
              />
            </div>

            <div>
              <label for="priceLabel" class="block text-sm font-medium text-gray-700">Label du prix</label>
              <select
                id="priceLabel"
                v-model="formPrice.price_label"
                class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                required
              >
                <option value="US">US</option>
                <option value="EU">EU</option>
              </select>
            </div>

            <div>
              <label for="unitPrice" class="block text-sm font-medium text-gray-700">Prix à l'unité</label>
              <input
                id="unitPrice"
                v-model="formPrice.unit_price"
                type="number"
                placeholder="Prix normal (ex: 10.99)"
                class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                required
              />
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700 mb-2">Quantité par palier</label>
              <div
                v-for="(tier, index) in formPrice.tier_quantity"
                :key="index"
                class="flex flex-col md:flex-row md:items-center gap-4 mb-3"
              >
                <div class="flex-1">
                  <label :for="'upTo' + index" class="block text-sm font-medium text-gray-700">Dernière unité</label>
                  <input
                    :id="'upTo' + index"
                    v-model="tier.up_to"
                    type="number"
                    placeholder="Ex: 10"
                    class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                  />
                </div>
                <div class="flex-1">
                  <label :for="'unitAmount' + index" class="block text-sm font-medium text-gray-700">Par unité</label>
                  <input
                    :id="'unitAmount' + index"
                    v-model="tier.unit_amount"
                    type="number"
                    placeholder="Ex: 10.99"
                    step="0.01"
                    class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                  />
                </div>
                <button
                  type="button"
                  @click="isEditing ? removeTierFromEdit(index) : removeTier(index)"
                  class="text-red-500 hover:text-red-700 transition duration-200"
                >
                  Supprimer
                </button>
              </div>
              <button
                type="button"
                @click="isEditing ? addTierToEdit() : addTier()"
                class="mt-2 bg-blue-500 text-white py-2 px-4 rounded shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              >
                Ajouter un palier
              </button>
            </div>

            <div>
              <label for="type" class="block text-sm font-medium text-gray-700">Type</label>
              <select
                id="type"
                v-model="formPrice.type"
                class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                required
              >
                <option value="main">Main</option>
                <option value="addon">Addon</option>
              </select>
            </div>

            <div>
              <label for="mode" class="block text-sm font-medium text-gray-700">Mode</label>
              <select
                id="mode"
                v-model="formPrice.mode"
                class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                required
              >
                <option value="subscription">Abonnement</option>
                <option value="one_time">Paiement unique</option>
              </select>
            </div>

            <div>
              <label for="availability" class="block text-sm font-medium text-gray-700">Disponibilité</label>
              <select
                id="availability"
                v-model="formPrice.availability"
                class="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                required
              >
                <option :value="true">Oui</option>
                <option :value="false">Non</option>
              </select>
            </div>

            <!-- Nouveau champ pour indiquer si c'est un "banner price" -->
            <div>
              <label for="isBannierPrice" class="block text-sm font-medium text-gray-700">Prix bannier</label>
              <input
                id="isBannierPrice"
                type="checkbox"
                v-model="formPrice.is_bannier_price"
                class="mt-1"
              />
            </div>

            <div class="flex flex-col gap-3">
              <button
                type="submit"
                class="w-full bg-green-500 text-white font-semibold py-2 px-4 rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-200"
              >
                {{ isEditing ? 'Modifier le prix' : 'Ajouter le prix' }}
              </button>
              <button
                type="button"
                v-if="isEditing"
                @click="resetPriceForm"
                class="w-full bg-gray-500 text-white font-semibold py-2 px-4 rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-200"
              >
                Annuler
              </button>
            </div>
          </form>
        </section>

        <!-- Liste des prix -->
        <section>
          <div v-if="prices.length" class="bg-white p-6 rounded-lg shadow-lg">
            <ul class="divide-y divide-gray-200">
              <li
                v-for="price in prices"
                :key="price.id"
                class="flex flex-col md:flex-row md:justify-between md:items-center py-4"
              >
                <div class="mb-2 md:mb-0">
                  <p class="font-semibold text-gray-800">
                    ID Stripe : {{ price.stripe_price_id }}
                  </p>
                  <p class="text-sm text-gray-600">Label : {{ price.price_label }}</p>
                  <p class="text-sm text-gray-600">Prix : {{ price.unit_price }} €</p>
                  <p class="text-sm text-gray-600">Pallier : {{ price.tier_quantity }}</p>

                  <div class="mt-1">
                    <label class="inline-flex items-center">
                      <input
                        type="checkbox"
                        :checked="price.is_bannier_price === 1"
                        @change="price.is_bannier_price = $event.target.checked ? 1 : 0"
                        class="form-checkbox h-4 w-4 text-green-600"
                      />
                      <span class="ml-2 text-sm text-gray-700">Banner Price</span>
                    </label>
                  </div>
                </div>
                <div class="flex gap-3">
                  <button
                    @click="loadPriceForEdit(price)"
                    class="bg-yellow-500 text-white font-semibold py-1 px-3 rounded hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-200"
                  >
                    Modifier
                  </button>
                  <button
                    @click="deletePrice(price.id)"
                    class="bg-red-500 text-white font-semibold py-1 px-3 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-200"
                  >
                    Supprimer
                  </button>
                  <button
                    @click="updateBannerPrice(price)"
                    class="bg-blue-500 text-white font-semibold py-1 px-3 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                  >
                    Mettre à jour bannier
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div v-else class="text-gray-500 mt-4">Aucun prix trouvé pour ce produit.</div>
        </section>
      </div>
    </div>
  </div>
</template>

  
  <script>
import axios from '../plugins/axios';

export default {
  name: 'StripeView',
  data() {
    return {
      products: [],
      prices: [],
      selectedProduct: null,
      isEditing: false,
      isEditingProduct: false,
      newProduct: {
        nameUS: '',
        nameEU: '',
        stripe_product_id: '',
        descriptionEU: '',
        descriptionUS: '',
        imagePath: '',
        imageFile: null,
      },
      selectedProductForEdit: null,
      newPrice: {
        stripe_price_id: '',
        price_label: '',
        unit_price: '',
        tier_quantity: [], 
        type: 'main',
        mode: 'subscription',
        availability: 1
      },
      selectedPrice: null,
      updatedDays: {},
      updatedAvailability: {},
      is_bannier_price: false,
    };
  },
  computed: {
    formPrice() {
      return this.isEditing ? this.selectedPrice : this.newPrice;
    },
  },
  methods: {

    async updateDaysAvailability(product) {
      const stripeId = product.stripe_product_id;
      const daysValue = parseInt(this.updatedDays[stripeId], 10) || 0;
      const availabilityValue = parseInt(this.updatedAvailability[stripeId], 10) || 0;

      try {
        const response = await axios.put(`/admin2/products/${stripeId}/days-availability`, {
          days: daysValue,
          availability: availabilityValue
        });

        console.log('Réponse mise à jour :', response.data);
        alert(`Mise à jour effectuée sur ${product.nameEU} - days=${daysValue}, availability=${availabilityValue}`);
      } catch (error) {
        console.error('Erreur lors de la mise à jour days/availability :', error);
        alert(`Erreur : ${error.message}`);
      }
    },



    async fetchProducts() {
      try {
        const response = await axios.get('/admin2/products');
        this.products = response.data.products;
        console.log(this.products)
      } catch (error) {
        console.error('Erreur lors de la récupération des produits :', error);
        this.$router.push({ path: '/login' });
      }
    },
    async handleImageUpload() {
      if (!this.newProduct.imageFile) {
        return;
      }
      const formData = new FormData();
      formData.append('image', this.newProduct.imageFile);

      try {
        const response = await axios.post('/admin2/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.newProduct.imagePath = response.data.imagePath;
      } catch (error) {
        console.error('Erreur lors de l\'upload de l\'image :', error);
        throw new Error('L\'upload de l\'image a échoué.');
      }
    },
    async addOrUpdateProduct() {
      try {
        if (this.newProduct.imageFile) {
          await this.handleImageUpload();
        }

        if (this.isEditingProduct) {
          await axios.post(`/admin2/products/${this.selectedProductForEdit.product_id}`, this.newProduct);
          await this.fetchProducts();
          this.resetProductForm();
        } else {
          const response = await axios.post('/admin2/products', this.newProduct);
          this.products.push(response.data.product);
          this.resetProductForm();
        }
      } catch (error) {
        console.error('Erreur lors de l’ajout ou de la modification du produit :', error);
      }
    },
    loadProductForEdit(product) {
      this.isEditingProduct = true;
      this.selectedProductForEdit = { ...product };
      this.newProduct = {
        nameUS: product.nameUS,
        nameEU: product.nameEU,
        stripe_product_id: product.stripe_product_id,
        descriptionUS: product.descriptionUS,
        descriptionEU: product.descriptionEU,
        imagePath: product.imagePath || '', 
        imageFile: null, 
      };
    },
    resetProductForm() {
      this.isEditingProduct = false;
      this.selectedProductForEdit = null;
      this.newProduct = {
        nameUS: '',
        nameEU: '',
        stripe_product_id: '',
        descriptionUS: '',
        descriptionEU: '',
        imagePath: '',
        imageFile: null,
      };
    },
    async selectProduct(product) {
      this.selectedProduct = product;
      console.log("test")
      console.log(this.selectedProduct)
      await this.fetchPrices(product.product_id);
    },
    async fetchPrices(productId) {
    try {
      const response = await axios.get(`/admin2/products/${productId}/prices`);

      this.prices = response.data.prices.map(price => ({
        ...price,
        tier_quantity: typeof price.tier_quantity === 'string' 
          ? JSON.parse(price.tier_quantity) 
          : price.tier_quantity,
      }));
    } catch (error) {
      console.error('Erreur lors de la récupération des prix :', error);
    }
  },
  async updateBannerPrice(price) {
    try {
      const payload = {
        stripePriceId: price.stripe_price_id,
        isBannierPrice: price.is_bannier_price 
      };

      const response = await axios.post('/admin2/set-bannier-price', payload);

      if (response.data.success) {
        alert('Mise à jour réussie :', response.data);
      } else {
        console.error('Erreur lors de la mise à jour:', response.data.error);
      }
    } catch (error) {
      console.error('Erreur API:', error);
    }
  },
  async addPrice() {
    try {
      if (!this.selectedProduct || !this.selectedProduct.product_id) {
        console.error("Erreur : Aucun produit sélectionné.");
        return;
      }

      const formattedTiers = this.formPrice.tier_quantity.map((tier) => ({
        up_to: tier.up_to ? parseInt(tier.up_to) : null,
        unit_amount: tier.unit_amount ? parseFloat(tier.unit_amount) : null,
      }));

      const newPrice = {
        ...this.formPrice,
        tier_quantity: formattedTiers,
        product_id: this.selectedProduct.product_id,
      };

      const response = await axios.post(
        `/admin2/products/${this.selectedProduct.product_id}/prices`,
        newPrice
      );
      console.log(response.data)
      if (response.data.prices) {
        response.data.prices.product_id = parseInt(response.data.prices.product_id, 10);
        this.prices.push(response.data.prices);
      }
      console.log(this.prices)
      this.formPrice = {
        stripe_price_id: '',
        price_label: '',
        unit_price: '',
        tier_quantity: [],
        type: 'main',
        mode: 'subscription',
        availability: 1,
        is_bannier_price: false
      };
      await this.fetchPrices(this.selectedProduct.product_id); 
    } catch (error) {
      console.error('Erreur lors de l’ajout du prix :', error);
    }
  },
    async deletePrice(priceId) {
      try {
        await axios.delete(`/admin2/prices/${priceId}`);
        await this.fetchPrices(this.selectedProduct.product_id); 
      } catch (error) {
        console.error('Erreur lors de la suppression du prix :', error);
      }
    },
    async deleteProduct(productId) {
      try {
        await axios.delete(`/admin2/products/${productId}`);
        this.products = this.products.filter((product) => product.product_id !== productId);
      } catch (error) {
        console.error('Erreur lors de la suppression du produit :', error);
      }
    },
    resetPriceForm() {
      this.isEditing = false;
      this.newPrice = {
        stripe_price_id: '',
        price_label: '',
        unit_price: '',
        tier_quantity: [],
        type: 'main',
        mode: 'subscription',
        availability: 1,
        is_bannier_price: false
      };
      this.selectedPrice = null;
    },
    async updatePrice(priceId) {
      try {
        const formattedTiers = this.selectedPrice.tier_quantity.map((tier) => ({
          up_to: tier.up_to ? parseInt(tier.up_to) : null,
          unit_amount: tier.unit_amount ? parseFloat(tier.unit_amount) : null,
        }));

        const updatedPrice = {
          ...this.selectedPrice,
          tier_quantity: formattedTiers,
        };
        console.log("updatedPrice");
        console.log(updatedPrice);
        await axios.put(`/admin2/prices/${priceId}`, updatedPrice);
        await this.fetchPrices(this.selectedProduct.product_id);

        this.resetPriceForm();
      } catch (error) {
        console.error('Erreur lors de la mise à jour du prix :', error);
      }
    },

    loadPriceForEdit(price) {
      this.isEditing = true;
      this.selectedPrice = { ...price };
      this.selectedPrice.tier_quantity = Array.isArray(price.tier_quantity)
        ? price.tier_quantity
        : JSON.parse(price.tier_quantity || '[]');
    },
    addTier() {
      this.newPrice.tier_quantity.push({ up_to: null, unit_amount: null });
    },
    removeTier(index) {
      this.newPrice.tier_quantity.splice(index, 1);
    },
    addTierToEdit() {
      if (this.selectedPrice) {
        this.selectedPrice.tier_quantity.push({ up_to: null, unit_amount: null });
      }
    },

    removeTierFromEdit(index) {
      if (this.selectedPrice) {
        this.selectedPrice.tier_quantity.splice(index, 1);
      }
  },
  },
  mounted() {
    this.fetchProducts().then(() => {
      this.products.forEach(product => {
        if (product.prices && product.prices.length > 0) {
          const firstPrice = product.prices[0];
          this.updatedDays = {
            ...this.updatedDays,
            [product.stripe_product_id]: firstPrice.days
          };
          this.updatedAvailability = {
            ...this.updatedAvailability,
            [product.stripe_product_id]: firstPrice.availability
          };
        }
      });
    });
    },
};
</script>

  
  <style scoped>
  .stripe-container {
    max-width: 800px;
    margin: 0 auto;
  }
  h1, h2 {
    font-family: 'Montserrat', sans-serif;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  </style>
