import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/Login.vue';
import MainLayout from '../layouts/MainLayout.vue';
import StripeView from '../views/Stripe.vue';
import ClientList from '../views/Clients.vue';
import MultipleClientList from '../views/ListMultipleStripe.vue'



const routes = [
  {
    path: '/',
    redirect: '/login', 
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/dashboard',
    component: MainLayout,
    children: [
      { path: 'stripe', name: 'Stripe', component: StripeView },
      { path: 'StripeMultiple', name: 'StripeMultiple', component: MultipleClientList },
      { path: 'ClientList', name: 'ClientList', component: ClientList },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
